import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { Box } from "@chakra-ui/core";

const SecondPage = () => (
  <Layout>
    <SEO title="Pagamento Eseguito con Successo!" />
    <Box
      paddingTop={100}
      alignItems="center"
      display="flex"
      flexDirection="column"
      justifySelf="center"
      alignSelf="center"
    >
      <h1 style={{ color: "white" }}>
        Grazie per aver Acquisato il Pacchetto Pro!
      </h1>
      <h2 style={{ color: "lime" }}>
        Ti verrà abilitato il pacchetto entro 24h
      </h2>
      <Link to="/" style={{ color: "white" }}>
        Torna allo Store
      </Link>
    </Box>
  </Layout>
);

export default SecondPage;
